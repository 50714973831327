import { Promise } from 'es6-promise';
import Transaction from './transaction';
import { extend } from '../common/utils';
import { PAGE_LOAD, NAME_UNKNOWN } from '../common/constants';
import { captureNavigation } from './capture-navigation';
import { __DEV__ } from '../env';
import { TRANSACTION_START, TRANSACTION_END } from '../common/constants';

var TransactionService = function () {
  function TransactionService(logger, config) {
    if (__DEV__ && typeof config === 'undefined') {
      logger.debug('TransactionService: config is not provided');
    }

    this._config = config;
    this._logger = logger;
    this.currentTransaction = undefined;
  }

  var _proto = TransactionService.prototype;

  _proto.ensureCurrentTransaction = function ensureCurrentTransaction(options) {
    if (!options) {
      options = this.createOptions();
    }

    var tr = this.getCurrentTransaction();

    if (tr) {
      return tr;
    } else {
      options.canReuse = true;
      options.managed = true;
      return this.createTransaction(undefined, undefined, options);
    }
  };

  _proto.getCurrentTransaction = function getCurrentTransaction() {
    if (this.currentTransaction && !this.currentTransaction.ended) {
      return this.currentTransaction;
    }
  };

  _proto.setCurrentTransaction = function setCurrentTransaction(value) {
    this.currentTransaction = value;
  };

  _proto.createTransaction = function createTransaction(name, type, options) {
    var tr = new Transaction(name, type, options);
    this.setCurrentTransaction(tr);

    if (options.checkBrowserResponsiveness) {
      this.startCounter(tr);
    }

    return tr;
  };

  _proto.startCounter = function startCounter(transaction) {
    transaction.browserResponsivenessCounter = 0;

    var interval = this._config.get('browserResponsivenessInterval');

    if (typeof interval === 'undefined') {
      if (__DEV__) {
        this._logger.debug('browserResponsivenessInterval is undefined!');
      }

      return;
    }

    var id = setInterval(function () {
      if (transaction.ended) {
        window.clearInterval(id);
      } else {
        transaction.browserResponsivenessCounter++;
      }
    }, interval);
  };

  _proto.createOptions = function createOptions(options) {
    var config = this._config.config;
    var presetOptions = {
      transactionSampleRate: config.transactionSampleRate
    };
    var perfOptions = extend(presetOptions, options);

    if (perfOptions.managed) {
      perfOptions = extend({
        pageLoadTraceId: config.pageLoadTraceId,
        pageLoadSampled: config.pageLoadSampled,
        pageLoadSpanId: config.pageLoadSpanId,
        pageLoadTransactionName: config.pageLoadTransactionName,
        checkBrowserResponsiveness: config.checkBrowserResponsiveness
      }, perfOptions);
    }

    return perfOptions;
  };

  _proto.startManagedTransaction = function startManagedTransaction(name, type, perfOptions) {
    var tr = this.getCurrentTransaction();

    if (!tr) {
      tr = this.createTransaction(name, type, perfOptions);
    } else if (tr.canReuse() && perfOptions.canReuse) {
      if (__DEV__) {
        this._logger.debug('Redefining the current transaction', tr, name, type, perfOptions);
      }

      tr.redefine(name, undefined, perfOptions);
    } else {
      if (__DEV__) {
        this._logger.debug('Ending old transaction', tr);
      }

      tr.end();
      tr = this.createTransaction(name, type, perfOptions);
    }

    tr.captureTimings = true;

    if (type === PAGE_LOAD) {
      tr.options.checkBrowserResponsiveness = false;

      if (perfOptions.pageLoadTraceId) {
        tr.traceId = perfOptions.pageLoadTraceId;
      }

      if (perfOptions.pageLoadSampled) {
        tr.sampled = perfOptions.pageLoadSampled;
      }

      if (tr.name === NAME_UNKNOWN && perfOptions.pageLoadTransactionName) {
        tr.name = perfOptions.pageLoadTransactionName;
      }
    }

    return tr;
  };

  _proto.startTransaction = function startTransaction(name, type, options) {
    var _this = this;

    var perfOptions = this.createOptions(options);
    var tr;

    if (perfOptions.managed) {
      tr = this.startManagedTransaction(name, type, perfOptions);
    } else {
      tr = new Transaction(name, type, perfOptions);
    }

    tr.onEnd = function () {
      return _this.handleTransactionEnd(tr);
    };

    if (__DEV__) {
      this._logger.debug('TransactionService.startTransaction', tr);
    }

    this._config.events.send(TRANSACTION_START, [tr]);

    return tr;
  };

  _proto.handleTransactionEnd = function handleTransactionEnd(tr) {
    var _this2 = this;

    return Promise.resolve().then(function () {
      if (__DEV__) {
        _this2._logger.debug('TransactionService transaction finished', tr);
      }

      if (_this2.shouldIgnoreTransaction(tr.name)) {
        return;
      }

      var breakdownMetrics = _this2._config.get('breakdownMetrics');

      if (breakdownMetrics) {
        tr.captureBreakdown();
      }

      if (tr.type === PAGE_LOAD) {
        var pageLoadTransactionName = _this2._config.get('pageLoadTransactionName');

        if (tr.name === NAME_UNKNOWN && pageLoadTransactionName) {
          tr.name = pageLoadTransactionName;
        }
      }

      captureNavigation(tr);

      _this2.add(tr);
    }, function (err) {
      if (__DEV__) {
        _this2._logger.debug('TransactionService transaction onEnd', err);
      }
    });
  };

  _proto.shouldIgnoreTransaction = function shouldIgnoreTransaction(transactionName) {
    var ignoreList = this._config.get('ignoreTransactions');

    if (ignoreList && ignoreList.length) {
      for (var i = 0; i < ignoreList.length; i++) {
        var element = ignoreList[i];

        if (typeof element.test === 'function') {
          if (element.test(transactionName)) {
            return true;
          }
        } else if (element === transactionName) {
          return true;
        }
      }
    }

    return false;
  };

  _proto.startSpan = function startSpan(name, type, options) {
    var trans = this.ensureCurrentTransaction();

    if (trans) {
      if (__DEV__) {
        this._logger.debug('TransactionService.startSpan', name, type);
      }

      var span = trans.startSpan(name, type, options);
      return span;
    }
  };

  _proto.add = function add(transaction) {
    this._config.events.send(TRANSACTION_END, [transaction]);

    if (__DEV__) {
      this._logger.debug('TransactionService.add', transaction);
    }
  };

  _proto.addTask = function addTask(taskId) {
    var tr = this.ensureCurrentTransaction();

    if (tr) {
      var taskId = tr.addTask(taskId);

      if (__DEV__) {
        this._logger.debug('TransactionService.addTask', taskId);
      }
    }

    return taskId;
  };

  _proto.removeTask = function removeTask(taskId) {
    var tr = this.getCurrentTransaction();

    if (tr) {
      tr.removeTask(taskId);

      if (__DEV__) {
        this._logger.debug('TransactionService.removeTask', taskId);
      }
    }
  };

  return TransactionService;
}();

export default TransactionService;