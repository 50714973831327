import { checkSameOrigin, isDtHeaderValid, merge, parseDtHeaderValue, getEarliestSpan, stripQueryStringFromUrl, getLatestNonXHRSpan } from '../common/utils';
import Url from '../common/url';
import { patchEventHandler } from '../common/patching';
import { globalState } from '../common/patching/patch-utils';
import { SCHEDULE, INVOKE, TRANSACTION_END, AFTER_EVENT, FETCH, HISTORY, XMLHTTPREQUEST } from '../common/constants';
import { truncateModel, SPAN_MODEL, TRANSACTION_MODEL } from '../common/truncate';
import { __DEV__ } from '../env';

var PerformanceMonitoring = function () {
  function PerformanceMonitoring(apmServer, configService, loggingService, transactionService) {
    this._apmServer = apmServer;
    this._configService = configService;
    this._logginService = loggingService;
    this._transactionService = transactionService;
  }

  var _proto = PerformanceMonitoring.prototype;

  _proto.init = function init(flags) {
    var _this = this;

    if (flags === void 0) {
      flags = {};
    }

    this._configService.events.observe(TRANSACTION_END + AFTER_EVENT, function (tr) {
      var payload = _this.createTransactionPayload(tr);

      if (payload) {
        _this._apmServer.addTransaction(payload);
      } else if (__DEV__) {
        _this._logginService.debug('Could not create a payload from the Transaction', tr);
      }
    });

    if (flags[HISTORY]) {
      patchEventHandler.observe(HISTORY, this.getHistorySub());
    }

    if (flags[XMLHTTPREQUEST]) {
      patchEventHandler.observe(XMLHTTPREQUEST, this.getXHRSub());
    }

    if (flags[FETCH]) {
      patchEventHandler.observe(FETCH, this.getFetchSub());
    }
  };

  _proto.getHistorySub = function getHistorySub() {
    var transactionService = this._transactionService;
    return function (event, task) {
      if (task.source === HISTORY && event === INVOKE) {
        transactionService.startTransaction(task.data.title, 'route-change', {
          managed: true,
          canReuse: true
        });
      }
    };
  };

  _proto.getXHRSub = function getXHRSub() {
    var _this2 = this;

    return function (event, task) {
      if (task.source === XMLHTTPREQUEST && !globalState.fetchInProgress) {
        _this2.processAPICalls(event, task);
      }
    };
  };

  _proto.getFetchSub = function getFetchSub() {
    var _this3 = this;

    return function (event, task) {
      if (task.source === FETCH) {
        _this3.processAPICalls(event, task);
      }
    };
  };

  _proto.processAPICalls = function processAPICalls(event, task) {
    var configService = this._configService;
    var transactionService = this._transactionService;

    if (event === SCHEDULE && task.data) {
      var requestUrl = new Url(task.data.url);
      var spanName = task.data.method + ' ' + (requestUrl.relative ? requestUrl.path : stripQueryStringFromUrl(requestUrl.href));
      var span = transactionService.startSpan(spanName, 'external.http');
      var taskId = transactionService.addTask();

      if (!span) {
        return;
      }

      var isDtEnabled = configService.get('distributedTracing');
      var dtOrigins = configService.get('distributedTracingOrigins');
      var currentUrl = new Url(window.location.href);
      var isSameOrigin = checkSameOrigin(requestUrl.origin, currentUrl.origin) || checkSameOrigin(requestUrl.origin, dtOrigins);
      var target = task.data.target;

      if (isDtEnabled && isSameOrigin && target) {
        this.injectDtHeader(span, target);
      }

      span.addContext({
        http: {
          method: task.data.method,
          url: requestUrl.href
        }
      });
      span.sync = task.data.sync;
      task.data.span = span;
      task.id = taskId;
    }

    if (event === INVOKE && task.data && task.data.span) {
      if (typeof task.data.target.status !== 'undefined') {
        task.data.span.addContext({
          http: {
            status_code: task.data.target.status
          }
        });
      } else if (task.data.response) {
        task.data.span.addContext({
          http: {
            status_code: task.data.response.status
          }
        });
      }

      task.data.span.end();
    }

    if (event === INVOKE && task.id) {
      transactionService.removeTask(task.id);
    }
  };

  _proto.injectDtHeader = function injectDtHeader(span, target) {
    var configService = this._configService;
    var headerName = configService.get('distributedTracingHeaderName');
    var headerValueCallback = configService.get('distributedTracingHeaderValueCallback');
    var headerValue = headerValueCallback(span);
    var isHeaderValid = isDtHeaderValid(headerValue);

    if (headerName && headerValue && isHeaderValid) {
      if (typeof target.setRequestHeader === 'function') {
        target.setRequestHeader(headerName, headerValue);
      } else if (target.headers && typeof target.headers.append === 'function') {
        target.headers.append(headerName, headerValue);
      } else {
        target[headerName] = headerValue;
      }
    }
  };

  _proto.extractDtHeader = function extractDtHeader(target) {
    var configService = this._configService;
    var headerName = configService.get('distributedTracingHeaderName');

    if (target) {
      return parseDtHeaderValue(target[headerName]);
    }
  };

  _proto.setTransactionContext = function setTransactionContext(transaction) {
    var context = this._configService.get('context');

    if (context) {
      transaction.addContext(context);
    }
  };

  _proto.filterTransaction = function filterTransaction(tr) {
    var transactionDurationThreshold = this._configService.get('transactionDurationThreshold');

    var duration = tr.duration();

    if (!duration) {
      if (__DEV__) {
        var message = 'Transaction was discarded! ';

        if (duration === 0) {
          message += "Transaction duration is 0";
        } else {
          message += "Transaction wasn't ended";
        }

        this._logginService.debug(message);
      }

      return false;
    }

    if (duration > transactionDurationThreshold) {
      if (__DEV__) {
        this._logginService.debug("Transaction was discarded! Transaction duration (" + duration + ") is greater than the transactionDurationThreshold configuration (" + transactionDurationThreshold + ")");
      }

      return false;
    }

    if (tr.spans.length === 0) {
      if (__DEV__) {
        this._logginService.debug("Transaction was discarded! Transaction does not include any spans");
      }

      return false;
    }

    if (!tr.sampled) {
      tr.resetSpans();
    }

    var browserResponsivenessInterval = this._configService.get('browserResponsivenessInterval');

    var checkBrowserResponsiveness = this._configService.get('checkBrowserResponsiveness');

    if (checkBrowserResponsiveness && tr.options.checkBrowserResponsiveness) {
      var buffer = this._configService.get('browserResponsivenessBuffer');

      var wasBrowserResponsive = this.checkBrowserResponsiveness(tr, browserResponsivenessInterval, buffer);

      if (!wasBrowserResponsive) {
        if (__DEV__) {
          this._logginService.debug('Transaction was discarded! Browser was not responsive enough during the transaction.', ' duration:', duration, ' browserResponsivenessCounter:', tr.browserResponsivenessCounter, 'interval:', browserResponsivenessInterval);
        }

        return false;
      }
    }

    return true;
  };

  _proto.adjustTransactionTime = function adjustTransactionTime(transaction) {
    var spans = transaction.spans;
    var earliestSpan = getEarliestSpan(spans);

    if (earliestSpan && earliestSpan._start < transaction._start) {
      transaction._start = earliestSpan._start;
    }

    var latestSpan = getLatestNonXHRSpan(spans);

    if (latestSpan && latestSpan._end > transaction._end) {
      transaction._end = latestSpan._end;
    }

    var transactionEnd = transaction._end;

    for (var i = 0; i < spans.length; i++) {
      var span = spans[i];

      if (span._end > transactionEnd) {
        span._end = transactionEnd;
        span.type += '.truncated';
      }

      if (span._start > transactionEnd) {
        span._start = transactionEnd;
      }
    }
  };

  _proto.prepareTransaction = function prepareTransaction(transaction) {
    transaction.spans.sort(function (spanA, spanB) {
      return spanA._start - spanB._start;
    });

    if (this._configService.get('groupSimilarSpans')) {
      var similarSpanThreshold = this._configService.get('similarSpanThreshold');

      transaction.spans = this.groupSmallContinuouslySimilarSpans(transaction, similarSpanThreshold);
    }

    transaction.spans = transaction.spans.filter(function (span) {
      return span.duration() > 0 && span._start >= transaction._start && span._end <= transaction._end;
    });
    this.setTransactionContext(transaction);
  };

  _proto.createTransactionDataModel = function createTransactionDataModel(transaction) {
    var configContext = this._configService.get('context');

    var transactionStart = transaction._start;
    var spans = transaction.spans.map(function (span) {
      var spanData = {
        id: span.id,
        transaction_id: transaction.id,
        parent_id: span.parentId || transaction.id,
        trace_id: transaction.traceId,
        name: span.name,
        type: span.type,
        subType: span.subType,
        action: span.action,
        sync: span.sync,
        start: span._start - transactionStart,
        duration: span.duration(),
        context: span.context
      };
      return truncateModel(SPAN_MODEL, spanData);
    });
    var context = merge({}, configContext, transaction.context);
    var transactionData = {
      id: transaction.id,
      trace_id: transaction.traceId,
      name: transaction.name,
      type: transaction.type,
      duration: transaction.duration(),
      spans: spans,
      context: context,
      marks: transaction.marks,
      breakdown: transaction.breakdownTimings,
      span_count: {
        started: spans.length
      },
      sampled: transaction.sampled
    };
    return truncateModel(TRANSACTION_MODEL, transactionData);
  };

  _proto.createTransactionPayload = function createTransactionPayload(transaction) {
    this.adjustTransactionTime(transaction);
    this.prepareTransaction(transaction);
    var filtered = this.filterTransaction(transaction);

    if (filtered) {
      return this.createTransactionDataModel(transaction);
    }
  };

  _proto.convertTransactionsToServerModel = function convertTransactionsToServerModel(transactions) {
    var _this4 = this;

    return transactions.map(function (tr) {
      return _this4.createTransactionDataModel(tr);
    });
  };

  _proto.groupSmallContinuouslySimilarSpans = function groupSmallContinuouslySimilarSpans(transaction, threshold) {
    var transDuration = transaction.duration();
    var spans = [];
    var lastCount = 1;
    transaction.spans.forEach(function (span, index) {
      if (spans.length === 0) {
        spans.push(span);
      } else {
        var lastSpan = spans[spans.length - 1];
        var isContinuouslySimilar = lastSpan.type === span.type && lastSpan.subType === span.subType && lastSpan.action === span.action && lastSpan.name === span.name && span.duration() / transDuration < threshold && (span._start - lastSpan._end) / transDuration < threshold;
        var isLastSpan = transaction.spans.length === index + 1;

        if (isContinuouslySimilar) {
          lastCount++;
          lastSpan._end = span._end;
        }

        if (lastCount > 1 && (!isContinuouslySimilar || isLastSpan)) {
          lastSpan.name = lastCount + 'x ' + lastSpan.name;
          lastCount = 1;
        }

        if (!isContinuouslySimilar) {
          spans.push(span);
        }
      }
    });
    return spans;
  };

  _proto.checkBrowserResponsiveness = function checkBrowserResponsiveness(transaction, interval, buffer) {
    var counter = transaction.browserResponsivenessCounter;

    if (typeof interval === 'undefined' || typeof counter === 'undefined') {
      return true;
    }

    var duration = transaction.duration();
    var expectedCount = Math.floor(duration / interval);
    var wasBrowserResponsive = counter + buffer >= expectedCount;
    return wasBrowserResponsive;
  };

  return PerformanceMonitoring;
}();

export default PerformanceMonitoring;