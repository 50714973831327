import { XMLHTTPREQUEST, FETCH, HISTORY, PAGE_LOAD, ERROR } from './constants';
export function getInstrumentationFlags(instrument, disabledInstrumentations) {
  var _flags;

  var flags = (_flags = {}, _flags[XMLHTTPREQUEST] = false, _flags[FETCH] = false, _flags[HISTORY] = false, _flags[PAGE_LOAD] = false, _flags[ERROR] = false, _flags);

  if (!instrument) {
    return flags;
  }

  Object.keys(flags).forEach(function (key) {
    if (disabledInstrumentations.indexOf(key) === -1) {
      flags[key] = true;
    }
  });
  return flags;
}