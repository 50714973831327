import Span from './span';
import { RESOURCE_INITIATOR_TYPES, MAX_SPAN_DURATION, USER_TIMING_THRESHOLD, PAGE_LOAD } from '../common/constants';
import { stripQueryStringFromUrl, getServerTimingInfo, getPageLoadMarks } from '../common/utils';
var eventPairs = [['domainLookupStart', 'domainLookupEnd', 'Domain lookup'], ['connectStart', 'connectEnd', 'Making a connection to the server'], ['requestStart', 'responseEnd', 'Requesting and receiving the document'], ['domLoading', 'domInteractive', 'Parsing the document, executing sync. scripts'], ['domContentLoadedEventStart', 'domContentLoadedEventEnd', 'Fire "DOMContentLoaded" event'], ['loadEventStart', 'loadEventEnd', 'Fire "load" event']];

function shouldCreateSpan(start, end, trStart, trEnd, baseTime) {
  if (baseTime === void 0) {
    baseTime = 0;
  }

  return typeof start === 'number' && typeof end === 'number' && start >= baseTime && end > start && start - baseTime >= trStart && end - baseTime <= trEnd && end - start < MAX_SPAN_DURATION && start - baseTime < MAX_SPAN_DURATION && end - baseTime < MAX_SPAN_DURATION;
}

function getResponseContext(perfTimingEntry) {
  var transferSize = perfTimingEntry.transferSize,
      encodedBodySize = perfTimingEntry.encodedBodySize,
      decodedBodySize = perfTimingEntry.decodedBodySize,
      serverTiming = perfTimingEntry.serverTiming;
  var respContext = {
    transfer_size: transferSize,
    encoded_body_size: encodedBodySize,
    decoded_body_size: decodedBodySize
  };
  var serverTimingStr = getServerTimingInfo(serverTiming);

  if (serverTimingStr) {
    respContext.headers = {
      'server-timing': serverTimingStr
    };
  }

  return respContext;
}

function createNavigationTimingSpans(timings, baseTime, trStart, trEnd) {
  var spans = [];

  for (var i = 0; i < eventPairs.length; i++) {
    var start = timings[eventPairs[i][0]];
    var end = timings[eventPairs[i][1]];

    if (!shouldCreateSpan(start, end, trStart, trEnd, baseTime)) {
      continue;
    }

    var span = new Span(eventPairs[i][2], 'hard-navigation.browser-timing');

    if (eventPairs[i][0] === 'requestStart') {
      span.pageResponse = true;
    }

    span._start = start - baseTime;
    span.ended = true;
    span._end = end - baseTime;
    spans.push(span);
  }

  return spans;
}

function createResourceTimingSpan(resourceTimingEntry) {
  var name = resourceTimingEntry.name,
      initiatorType = resourceTimingEntry.initiatorType,
      startTime = resourceTimingEntry.startTime,
      responseEnd = resourceTimingEntry.responseEnd;
  var kind = 'resource';

  if (initiatorType) {
    kind += '.' + initiatorType;
  }

  var spanName = stripQueryStringFromUrl(name);
  var span = new Span(spanName, kind);
  span.addContext({
    http: {
      url: name,
      response: getResponseContext(resourceTimingEntry)
    }
  });
  span._start = startTime;
  span.end();
  span._end = responseEnd;
  return span;
}

function createResourceTimingSpans(entries, filterUrls, trStart, trEnd) {
  var spans = [];

  for (var i = 0; i < entries.length; i++) {
    var _entries$i = entries[i],
        initiatorType = _entries$i.initiatorType,
        name = _entries$i.name,
        startTime = _entries$i.startTime,
        responseEnd = _entries$i.responseEnd;

    if (initiatorType === 'xmlhttprequest' || initiatorType === 'fetch' || !name) {
      continue;
    }

    if (RESOURCE_INITIATOR_TYPES.indexOf(initiatorType) !== -1) {
      if (!shouldCreateSpan(startTime, responseEnd, trStart, trEnd)) {
        continue;
      }

      spans.push(createResourceTimingSpan(entries[i]));
    } else {
      if (initiatorType != null) {
        continue;
      }

      var foundAjaxReq = false;

      for (var j = 0; j < filterUrls.length; j++) {
        var idx = name.lastIndexOf(filterUrls[j]);

        if (idx > -1 && idx === name.length - filterUrls[j].length) {
          foundAjaxReq = true;
          break;
        }
      }

      if (!foundAjaxReq && shouldCreateSpan(startTime, responseEnd, trStart, trEnd)) {
        spans.push(createResourceTimingSpan(entries[i]));
      }
    }
  }

  return spans;
}

function createUserTimingSpans(entries, trStart, trEnd) {
  var userTimingSpans = [];

  for (var i = 0; i < entries.length; i++) {
    var _entries$i2 = entries[i],
        name = _entries$i2.name,
        startTime = _entries$i2.startTime,
        duration = _entries$i2.duration;
    var end = startTime + duration;

    if (duration <= USER_TIMING_THRESHOLD || !shouldCreateSpan(startTime, end, trStart, trEnd)) {
      continue;
    }

    var kind = 'app';
    var span = new Span(name, kind);
    span._start = startTime;
    span.end();
    span._end = end;
    userTimingSpans.push(span);
  }

  return userTimingSpans;
}

function getApiSpanNames(_ref) {
  var spans = _ref.spans;
  var apiCalls = [];

  for (var i = 0; i < spans.length; i++) {
    var span = spans[i];

    if (span.type === 'external' && span.subType === 'http') {
      continue;
    }

    apiCalls.push(span.name.split(' ')[1]);
  }

  return apiCalls;
}

function captureNavigation(transaction) {
  if (!transaction.captureTimings) {
    return;
  }

  var perf = window.performance;
  var trEnd = transaction._end;

  if (transaction.type === PAGE_LOAD) {
    if (transaction.marks && transaction.marks.custom) {
      var customMarks = transaction.marks.custom;
      Object.keys(customMarks).forEach(function (key) {
        customMarks[key] += transaction._start;
      });
    }

    var trStart = 0;
    transaction._start = trStart;
    var timings = perf.timing;
    createNavigationTimingSpans(timings, timings.fetchStart, trStart, trEnd).forEach(function (span) {
      span.traceId = transaction.traceId;
      span.sampled = transaction.sampled;

      if (span.pageResponse && transaction.options.pageLoadSpanId) {
        span.id = transaction.options.pageLoadSpanId;
      }

      transaction.spans.push(span);
    });
    transaction.addMarks(getPageLoadMarks());
  }

  if (typeof perf.getEntriesByType === 'function') {
    var _trStart = transaction._start;
    var resourceEntries = perf.getEntriesByType('resource');
    var apiCalls = getApiSpanNames(transaction);
    createResourceTimingSpans(resourceEntries, apiCalls, _trStart, trEnd).forEach(function (span) {
      return transaction.spans.push(span);
    });
    var userEntries = perf.getEntriesByType('measure');
    createUserTimingSpans(userEntries, _trStart, trEnd).forEach(function (span) {
      return transaction.spans.push(span);
    });

    if (transaction.type === PAGE_LOAD) {
      var navigationEntry = perf.getEntriesByType('navigation');

      if (navigationEntry && navigationEntry.length > 0) {
        navigationEntry = navigationEntry[0];
        transaction.addContext({
          response: getResponseContext(navigationEntry)
        });
      }
    }
  }
}

export { captureNavigation, createNavigationTimingSpans, createResourceTimingSpans, createUserTimingSpans };