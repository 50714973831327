import { apmSymbol, patchMethod, XHR_SYNC, XHR_URL, XHR_METHOD, XHR_IGNORE } from './patch-utils';
import { SCHEDULE, INVOKE, CLEAR, XMLHTTPREQUEST, ADD_EVENT_LISTENER_STR, REMOVE_EVENT_LISTENER_STR } from '../constants';
var XHR_TASK = apmSymbol('xhrTask');
var XHR_LISTENER = apmSymbol('xhrListener');
var XHR_SCHEDULED = apmSymbol('xhrScheduled');
export function patchXMLHttpRequest(callback) {
  var XMLHttpRequestPrototype = XMLHttpRequest.prototype;
  var oriAddListener = XMLHttpRequestPrototype[ADD_EVENT_LISTENER_STR];
  var oriRemoveListener = XMLHttpRequestPrototype[REMOVE_EVENT_LISTENER_STR];

  if (!oriAddListener) {
    var XMLHttpRequestEventTarget = window['XMLHttpRequestEventTarget'];

    if (XMLHttpRequestEventTarget) {
      var XMLHttpRequestEventTargetPrototype = XMLHttpRequestEventTarget.prototype;
      oriAddListener = XMLHttpRequestEventTargetPrototype[ADD_EVENT_LISTENER_STR];
      oriRemoveListener = XMLHttpRequestEventTargetPrototype[REMOVE_EVENT_LISTENER_STR];
    }
  }

  var READY_STATE_CHANGE = 'readystatechange';

  function invokeTask(task) {
    task.state = INVOKE;

    if (!task.ignore) {
      callback(INVOKE, task);
    }
  }

  function scheduleTask(task) {
    XMLHttpRequest[XHR_SCHEDULED] = false;
    task.state = SCHEDULE;

    if (!task.ignore) {
      callback(SCHEDULE, task);
    }

    var data = task.data;
    var target = data.target;
    var listener = target[XHR_LISTENER];

    if (!oriAddListener) {
      oriAddListener = target[ADD_EVENT_LISTENER_STR];
      oriRemoveListener = target[REMOVE_EVENT_LISTENER_STR];
    }

    if (listener) {
      oriRemoveListener.call(target, READY_STATE_CHANGE, listener);
    }

    var newListener = target[XHR_LISTENER] = function () {
      if (target.readyState === target.DONE) {
        if (!data.aborted && XMLHttpRequest[XHR_SCHEDULED] && task.state === SCHEDULE) {
          invokeTask(task);
        }
      }
    };

    oriAddListener.call(target, READY_STATE_CHANGE, newListener);
    var storedTask = target[XHR_TASK];

    if (!storedTask) {
      target[XHR_TASK] = task;
    }

    var result = sendNative.apply(target, data.args);
    XMLHttpRequest[XHR_SCHEDULED] = true;
    return result;
  }

  function clearTask(task) {
    task.state = CLEAR;
    callback(CLEAR, task);
    var data = task.data;
    data.aborted = true;
  }

  var openNative = patchMethod(XMLHttpRequestPrototype, 'open', function () {
    return function (self, args) {
      self[XHR_METHOD] = args[0];
      self[XHR_URL] = args[1];
      self[XHR_SYNC] = args[2] === false;
      return openNative.apply(self, args);
    };
  });
  var sendNative = patchMethod(XMLHttpRequestPrototype, 'send', function () {
    return function (self, args) {
      var task = {
        source: XMLHTTPREQUEST,
        state: '',
        type: 'macroTask',
        ignore: self[XHR_IGNORE],
        data: {
          target: self,
          method: self[XHR_METHOD],
          sync: self[XHR_SYNC],
          url: self[XHR_URL],
          args: args,
          aborted: false
        }
      };
      var result = scheduleTask(task);

      if (self[XHR_SYNC]) {
        invokeTask(task);
      }

      return result;
    };
  });
  var abortNative = patchMethod(XMLHttpRequestPrototype, 'abort', function () {
    return function (self, args) {
      var task = self[XHR_TASK];

      if (task && typeof task.type === 'string') {
        if (task.data && task.data.aborted) {
          return;
        }

        clearTask(task);
      }

      return abortNative.apply(self, args);
    };
  });
}