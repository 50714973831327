function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import { Promise } from 'es6-promise';
import Queue from './queue';
import throttle from './throttle';
import NDJSON from './ndjson';
import { XHR_IGNORE } from './patching/patch-utils';
import { truncateModel, METADATA_MODEL } from './truncate';
import { __DEV__ } from '../env';

var ApmServer = function () {
  function ApmServer(configService, loggingService) {
    this._configService = configService;
    this._loggingService = loggingService;
    this.errorQueue = undefined;
    this.transactionQueue = undefined;
    this.throttleAddError = undefined;
    this.throttleAddTransaction = undefined;
    this.initialized = false;
  }

  var _proto = ApmServer.prototype;

  _proto.init = function init() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    this.initErrorQueue();
    this.initTransactionQueue();
  };

  _proto.createMetaData = function createMetaData() {
    var cfg = this._configService;
    var metadata = {
      service: {
        name: cfg.get('serviceName'),
        version: cfg.get('serviceVersion'),
        agent: {
          name: 'js-base',
          version: cfg.version
        },
        language: {
          name: 'javascript'
        },
        environment: cfg.get('environment')
      }
    };
    return truncateModel(METADATA_MODEL, metadata);
  };

  _proto._postJson = function _postJson(endPoint, payload) {
    return this._makeHttpRequest('POST', endPoint, {
      payload: payload,
      headers: {
        'Content-Type': 'application/x-ndjson'
      }
    });
  };

  _proto._constructError = function _constructError(reason) {
    var url = reason.url,
        status = reason.status,
        responseText = reason.responseText;
    var message = url + ' HTTP status: ' + status;

    if (__DEV__ && responseText) {
      try {
        var serverErrors = [];
        var response = JSON.parse(responseText);

        if (response.errors && response.errors.length > 0) {
          response.errors.forEach(function (err) {
            return serverErrors.push(err.message);
          });
          message += ' ' + serverErrors.join(',');
        }
      } catch (e) {
        this._loggingService.debug('Error parsing response from APM server', e);
      }
    }

    return new Error(message);
  };

  _proto._makeHttpRequest = function _makeHttpRequest(method, url, _temp) {
    var _ref = _temp === void 0 ? {
      timeout: 10000
    } : _temp,
        timeout = _ref.timeout,
        payload = _ref.payload,
        headers = _ref.headers;

    return new Promise(function (resolve, reject) {
      var xhr = new window.XMLHttpRequest();
      xhr[XHR_IGNORE] = true;
      xhr.open(method, url, true);
      xhr.timeout = timeout;

      if (headers) {
        for (var header in headers) {
          if (headers.hasOwnProperty(header)) {
            xhr.setRequestHeader(header, headers[header]);
          }
        }
      }

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          var status = xhr.status,
              responseText = xhr.responseText;

          if (status === 0 || status > 399 && status < 600) {
            reject({
              url: url,
              status: status,
              responseText: responseText
            });
          } else {
            resolve(responseText);
          }
        }
      };

      xhr.onerror = function () {
        var status = xhr.status,
            responseText = xhr.responseText;
        reject({
          url: url,
          status: status,
          responseText: responseText
        });
      };

      xhr.send(payload);
    });
  };

  _proto._createQueue = function _createQueue(onFlush) {
    var queueLimit = this._configService.get('queueLimit');

    var flushInterval = this._configService.get('flushInterval');

    return new Queue(onFlush, {
      queueLimit: queueLimit,
      flushInterval: flushInterval
    });
  };

  _proto.fetchConfig = function fetchConfig(serviceName, environment) {
    var _this = this;

    var serverUrl = this._configService.get('serverUrl');

    var configEndpoint = serverUrl + "/config/v1/agents";

    if (!serviceName) {
      return Promise.reject('serviceName is required for fetching central config.');
    }

    configEndpoint += "?service.name=" + serviceName;

    if (environment) {
      configEndpoint += "&service.environment=" + environment;
    }

    return this._makeHttpRequest('GET', configEndpoint, {
      timeout: 5000
    }).then(function (response) {
      return JSON.parse(response);
    }).catch(function (reason) {
      var error = _this._constructError(reason);

      return Promise.reject(error);
    });
  };

  _proto.initErrorQueue = function initErrorQueue() {
    var _this2 = this;

    if (this.errorQueue) {
      this.errorQueue.flush();
    }

    this.errorQueue = this._createQueue(function (errors) {
      var p = _this2.sendErrors(errors);

      if (p) {
        p.catch(function (reason) {
          _this2._loggingService.warn('Failed sending errors!', _this2._constructError(reason));
        });
      }
    });

    var limit = this._configService.get('errorThrottleLimit');

    var interval = this._configService.get('errorThrottleInterval');

    this.throttleAddError = throttle(this.errorQueue.add.bind(this.errorQueue), function () {
      return _this2._loggingService.warn('Dropped error due to throttling!');
    }, {
      limit: limit,
      interval: interval
    });
  };

  _proto.initTransactionQueue = function initTransactionQueue() {
    var _this3 = this;

    if (this.transactionQueue) {
      this.transactionQueue.flush();
    }

    this.transactionQueue = this._createQueue(function (transactions) {
      var p = _this3.sendTransactions(transactions);

      if (p) {
        p.catch(function (reason) {
          _this3._loggingService.warn('Failed sending transactions!', _this3._constructError(reason));
        });
      }
    });

    var limit = this._configService.get('transactionThrottleLimit');

    var interval = this._configService.get('transactionThrottleInterval');

    this.throttleAddTransaction = throttle(this.transactionQueue.add.bind(this.transactionQueue), function () {
      return _this3._loggingService.warn('Dropped transaction due to throttling!');
    }, {
      limit: limit,
      interval: interval
    });
  };

  _proto.addError = function addError(error) {
    if (!this.errorQueue) {
      this.initErrorQueue();
    }

    this.throttleAddError(error);
  };

  _proto.addTransaction = function addTransaction(transaction) {
    if (!this.transactionQueue) {
      this.initTransactionQueue();
    }

    this.throttleAddTransaction(transaction);
  };

  _proto.ndjsonErrors = function ndjsonErrors(errors) {
    return errors.map(function (error) {
      return NDJSON.stringify({
        error: error
      });
    });
  };

  _proto.ndjsonMetricsets = function ndjsonMetricsets(metricsets) {
    var timestamp = Date.now();
    return metricsets.map(function (metricset) {
      return NDJSON.stringify({
        metricset: _extends({
          timestamp: timestamp
        }, metricset)
      });
    }).join('');
  };

  _proto.ndjsonTransactions = function ndjsonTransactions(transactions) {
    var _this4 = this;

    return transactions.map(function (tr) {
      var spans = '';

      if (tr.spans) {
        spans = tr.spans.map(function (span) {
          return NDJSON.stringify({
            span: span
          });
        }).join('');
        delete tr.spans;
      }

      var breakdowns = '';

      if (tr.breakdown) {
        breakdowns = _this4.ndjsonMetricsets(tr.breakdown);
        delete tr.breakdown;
      }

      return NDJSON.stringify({
        transaction: tr
      }) + spans + breakdowns;
    });
  };

  _proto._send = function _send(data, type) {
    if (data === void 0) {
      data = [];
    }

    if (type === void 0) {
      type = 'transaction';
    }

    if (data.length === 0) {
      return;
    }

    var _this$createMetaData = this.createMetaData(),
        service = _this$createMetaData.service;

    var payload = {
      service: service,
      data: data
    };

    var filteredPayload = this._configService.applyFilters(payload);

    if (!filteredPayload) {
      this._loggingService.warn('Dropped payload due to filtering!');

      return;
    }

    var ndjson;

    if (type === 'errors') {
      ndjson = this.ndjsonErrors(filteredPayload.data);
    } else if (type === 'transaction') {
      ndjson = this.ndjsonTransactions(filteredPayload.data);
    } else {
      if (__DEV__) {
        this._loggingService.debug('Dropped payload due to unknown data type');
      }

      return;
    }

    ndjson.unshift(NDJSON.stringify({
      metadata: {
        service: filteredPayload.service
      }
    }));
    var ndjsonPayload = ndjson.join('');

    var endPoint = this._configService.getEndpointUrl();

    return this._postJson(endPoint, ndjsonPayload);
  };

  _proto.sendTransactions = function sendTransactions(transactions) {
    return this._send(transactions);
  };

  _proto.sendErrors = function sendErrors(errors) {
    return this._send(errors, 'errors');
  };

  return ApmServer;
}();

export default ApmServer;