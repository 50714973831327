import { createStackTraces, filterInvalidFrames } from './stack-trace';
import { getPageMetadata, generateRandomId, merge } from '../common/utils';
import { truncateModel, ERROR_MODEL } from '../common/truncate';

var ErrorLogging = function () {
  function ErrorLogging(apmServer, configService, transactionService) {
    this._apmServer = apmServer;
    this._configService = configService;
    this._transactionService = transactionService;
  }

  var _proto = ErrorLogging.prototype;

  _proto.createErrorDataModel = function createErrorDataModel(errorEvent) {
    var frames = createStackTraces(errorEvent);
    var filteredFrames = filterInvalidFrames(frames);
    var culprit = '(inline script)';
    var lastFrame = filteredFrames[filteredFrames.length - 1];

    if (lastFrame && lastFrame.filename) {
      culprit = lastFrame.filename;
    }

    var message = errorEvent.message,
        error = errorEvent.error;
    var errorMessage = message;
    var errorType = '';
    var errorContext = {};

    if (error && typeof error === 'object') {
      errorMessage = errorMessage || error.message;
      errorType = error.name;
      errorContext = this._getErrorProperties(error);
    }

    if (!errorType) {
      if (errorMessage && errorMessage.indexOf(':') > -1) {
        errorType = errorMessage.split(':')[0];
      }
    }

    var configContext = this._configService.get('context');

    var browserMetadata = getPageMetadata();
    var context = merge({}, browserMetadata, configContext, errorContext);
    var errorObject = {
      id: generateRandomId(),
      culprit: culprit,
      exception: {
        message: errorMessage,
        stacktrace: filteredFrames,
        type: errorType
      },
      context: context
    };

    var currentTransaction = this._transactionService.getCurrentTransaction();

    if (currentTransaction) {
      errorObject.trace_id = currentTransaction.traceId;
      errorObject.parent_id = currentTransaction.id;
      errorObject.transaction_id = currentTransaction.id;
      errorObject.transaction = {
        type: currentTransaction.type,
        sampled: currentTransaction.sampled
      };
    }

    return truncateModel(ERROR_MODEL, errorObject);
  };

  _proto.logErrorEvent = function logErrorEvent(errorEvent, sendImmediately) {
    if (typeof errorEvent === 'undefined') {
      return;
    }

    var errorObject = this.createErrorDataModel(errorEvent);

    if (typeof errorObject.exception.message === 'undefined') {
      return;
    }

    if (sendImmediately) {
      return this._apmServer.sendErrors([errorObject]);
    } else {
      return this._apmServer.addError(errorObject);
    }
  };

  _proto.registerListeners = function registerListeners() {
    var _this = this;

    window.addEventListener('error', function (errorEvent) {
      return _this.logErrorEvent(errorEvent);
    });
    window.addEventListener('unhandledrejection', function (promiseRejectionEvent) {
      return _this.logPromiseEvent(promiseRejectionEvent);
    });
  };

  _proto.logPromiseEvent = function logPromiseEvent(promiseRejectionEvent) {
    var prefix = 'Unhandled promise rejection: ';
    var reason = promiseRejectionEvent.reason;

    if (reason == null) {
      this.logError(prefix + '<no reason specified>');
    } else if (typeof reason.message === 'string') {
      this.logError({
        message: prefix + reason.message,
        stack: reason.stack ? reason.stack : null
      });
    } else if (typeof reason !== 'object') {
      this.logError(prefix + reason);
    }
  };

  _proto.logError = function logError(messageOrError) {
    var errorEvent = {};

    if (typeof messageOrError === 'string') {
      errorEvent.message = messageOrError;
    } else {
      errorEvent.error = messageOrError;
    }

    return this.logErrorEvent(errorEvent);
  };

  _proto._getErrorProperties = function _getErrorProperties(error) {
    var properties = {};
    Object.keys(error).forEach(function (key) {
      if (key === 'stack') return;
      var val = error[key];
      if (val === null) return;

      switch (typeof val) {
        case 'function':
          return;

        case 'object':
          if (typeof val.toISOString !== 'function') return;
          val = val.toISOString();
      }

      properties[key] = val;
    });
    return properties;
  };

  return ErrorLogging;
}();

export default ErrorLogging;